<template>
<div class="my-qrcode">
    <top-bar :title="'居民果果'" :left="true"/>
  <img class="qrcode_bgc" src="@/assets/img/communityCode.png" alt="">
  <p>我的二维码</p>
  <div class="qrcode" ref="qrcodeRef" id="userQrcode"></div>
</div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {createQrcode} from '@/utils/utils'

export default {
  components:{
    topBar
  },
    data () {
    return {
       config: {
　　　　　value: 'www.baidu.com',　　//显示的值、跳转的地址
　　　　　BYTE_POS: 'black'
        　　},
    }
  },
  methods :{
    // 创建个人二维码
    creatUserQrCode () {
      let Base64 = require('js-base64').Base64
      debugger
      let a = Base64.encode('{"type": "user", "id": ' + this.$userId + ',"version": "2" }')
      createQrcode(this.$refs.qrcodeRef, 'userQrcode', a, 200)
    },
  },
  mounted() {
    this.creatUserQrCode()
  }
}
</script>

<style lang="scss" scoped>
  .my-qrcode {
    height: 100vh;
    position: relative;
    margin-top: 100px;
    .qrcode_bgc {
      width: 750px;
      height: 750px;
    }
    p {
      width: 200px;
      text-align: center;
      position: absolute;
      top: 50px;
      left: 50%;
      margin-left: -100px;
      font-size: 36px;
    }
    .qrcode {
      position: absolute;
      top: 200px;
      left: 50%;
      margin-left: -180px;
    }
  }
</style>
